/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { AnimatePresence, motion } from 'framer-motion'

import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'

// Elements
import AnchorPrimary from 'components/elements/Anchors/AnchorPrimary'
import ParseContent from 'components/shared/ParseContent'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 36px;
    line-height: 44px;
    & > strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

interface ProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_HighlightedPostTypes
}

const Projects: React.FC<ProjectsProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <div className="container">
      <div className="row justify-content-between mb-5">
        <div className="col-md-6 mb-4 mb-md-0">
          <Content content={fields.description} />
        </div>
        <div className="col-md-6 d-flex justify-content-end align-items-end">
          <AnchorPrimary href={fields.link?.url || '#'}>
            {fields.link?.title}
          </AnchorPrimary>
        </div>
      </div>
      <Blog fields={fields} />
    </div>
  </section>
)

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_HighlightedPostTypes
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpProject,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedProjectsQueryQuery>(graphql`
    query highlightedProjectsQuery {
      allWpProject(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalProjectFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpProject.edges

  return (
    <BlogBink
      posts={posts as BlogBinkPosts}
      id="highlighted-Projects"
      showIds={fields?.projectrelation?.map(
        (relation: any) => relation.databaseId
      )}
      limit={Infinity}
    >
      <BlogGrid fields={fields} />
    </BlogBink>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_HighlightedPostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const blogBink = useBlogBink()

  return (
    <div className="row">
      {blogBink.posts.map((post, index) => {
        const { node }: any = post

        const open: boolean = currentIndex === index

        return (
          <div
            key={post.node.id}
            className="col-lg-3 col-md-4 col-6 mb-5 mb-lg-0"
          >
            <motion.div
              whileHover={() => setCurrentIndex(index)}
              onTapStart={() => setCurrentIndex(index)}
              className="h-100"
            >
              <BlogGridPost node={node} fields={fields} overlay={open} />
            </motion.div>
          </div>
        )
      })}
    </div>
  )
}

const Recap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 2;

  & h2 {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  @media (max-width: 575px) {
  }
`

const Front = styled(motion.div)`
  background: linear-gradient(
    0deg,
    #18263f 0%,
    rgba(24, 38, 63, 0.0203309) 97.65%,
    rgba(24, 38, 63, 0) 100%
  );
  color: ${({ theme }) => theme.color.light};
`

const Overlay = styled(motion.div)`
  position: relative;
  z-index: 99;
  background-color: rgba(210, 164, 46, 0.85);
  color: ${({ theme }) => theme.color.dark};

  & a {
    @media (max-width: 449px) {
      font-size: 12px;
    }
  }
`

const Excerpt = styled(ParseContent)`
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @media (min-width: 576px) {
      -webkit-line-clamp: 6;
    }
    @media (max-width: 575px) {
      -webkit-line-clamp: 3;
    }
    @media (max-width: 375px) {
      -webkit-line-clamp: 2;
    }
  }
`

const Caption = styled.span`
  @media (max-width: 375px) {
    display: none;
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 1400px) {
    max-height: 410px !important;
  }
  @media (max-width: 1399px) {
    height: 370px !important;
  }
  @media (max-width: 991px) {
    height: 300px !important;
  }
  @media (max-width: 767px) {
    height: 200px !important;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_HighlightedPostTypes
  overlay: boolean
  node: any
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({
  node,
  overlay,
  fields,
}) => {
  const overlayAnimations = {
    collapsed: {
      height: 0,
    },
    open: {
      height: '100%',
    },
  }

  const overlayTransition = {
    duration: 0.1,
    ease: [0.17, 0.67, 0.83, 0.67],
  }

  return (
    <div className="position-relative h-100">
      <Recap className="position-absolute w-100 h-100 d-flex flex-column justify-content-end overflow-hidden">
        {!overlay && (
          <Front>
            <div className="p-3 p-sm-4">
              <h2>{node.title}</h2>
              <span>{node.projectrecap.caption}</span>
            </div>
          </Front>
        )}

        <AnimatePresence>
          {overlay && (
            <Overlay
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={overlayAnimations}
              transition={overlayTransition}
            >
              <div className="p-3 p-sm-4 d-flex flex-column justify-content-between h-100">
                <div>
                  <h2>{node.title}</h2>
                  <Caption>{node.projectrecap.caption}</Caption>
                </div>

                <Excerpt content={node.projectrecap.excerpt} />
                <AnchorPrimary href={node.uri}>
                  {fields.readmoretext}
                </AnchorPrimary>
              </div>
            </Overlay>
          )}
        </AnimatePresence>
      </Recap>
      <Image
        image={node.projectrecap.thumbnail}
        alt=""
        className="h-100"
        style={{ maxHeight: 410 }}
      />
    </div>
  )
}

export default Projects
